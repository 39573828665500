<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: {{$t('AddNew')}} Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">

    <div class="mt-6 flex items-center justify-between px-6">
        <h4 style="color: white;">{{ Object.entries(this.data).length === 0 ? $t("AddCapital") : $t("upCapital") }}</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings">

      <div class="p-6">
        <!-- NAME -->
        <label style="font-weight: bold;color: white;">{{$t("NameAr")}} </label>
        <vs-input style="color: black;" v-model="NameAR" class="mt-5 w-full" name="NameAR"/>
        <span style="color: white;" class="text-danger text-sm" v-show="errors.has('nameAR')">{{ errors.first('nameAR') }}</span>
      </div>
      <div class="p-6">
        <!-- NAME -->
        <label style="font-weight: bold;color: white;">{{$t("NameEn")}} </label>
        <vs-input style="color: black;" v-model="NameEN" class="mt-5 w-full" name="NameEN"/>
        <span style="color: white;" class="text-danger text-sm" v-show="errors.has('nameEN')">{{ errors.first('nameEN') }}</span>
      </div>
      <div class="p-6">
      <label style="font-weight: bold;color: white;">{{ $t("Country") }}</label>
          <v-select style="color: white;"
          label="NameAR"
            name="NameAR"
             class="mt-5 w-full sclass"
            v-model="CountryId"
            :reduce="country => country.Id"
            :options="countries"
          /> 
        </div>

      <!-- <div class="p-6">
        <vs-input :label="'Format Phone'" v-model="FormatPhone" class="mt-5 w-full" name="name"  />
        <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>
      </div> -->

    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid" style="background-color: aliceblue; color: black; font-weight: bold;" color="info">{{$t("Save")}}</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{$t("Cancel")}}</vs-button>
    </div>

  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moduleCountry from "@/store/Country/moduleCountry.js";
export default {
  components: {
    VuePerfectScrollbar,
   
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    },

  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { Id, NameAR,NameEN,CountryId} = JSON.parse(
          JSON.stringify(this.data)
        );
        this.Id = Id;
        this.NameAR = NameAR;
        this.NameEN = NameEN;
        this.CountryId=CountryId;
        //this.FormatPhone = FormatPhone;
      }
console.log("xxx", this.Id,this.NameAR , this.NameEN,this.CountryId)
    }
  },
  data() {
    return {
    
      Id: 0,
      NameAR: "",
      Code: "",
      NameEN:"",
      CountryId:0,
      //FormatPhone:"",
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");

        }
      }
    },
    isFormValid() {
      console.log("selectedCountry:", this.CountryId);
      return !this.errors.any() && this.NameAR && this.NameEN && this.CountryId ;
      
    },
    countries() {
      debugger;
      return this.$store.state.countriesList.countries;
    },
  },
  methods: {
    initValues() {
      if (this.data.Id) return;
      this.Id = 0;
      this.NameAR = "";
      this.NameEN = "";
      this.CountryId = 0;
      //this.FormatPhone ="";
    },
    submitData() {
      debugger;
      this.$validator.validateAll().then(result => {
        if (result) {
          debugger;
          const obj = {
            Id: this.Id,
            NameAR: this.NameAR,
            NameEN: this.NameEN,
            CountryID: this.CountryId,
            //FormatPhone:this.FormatPhone,
          };

          if (this.Id !== null && this.Id > 0) {
            this.$store.dispatch("RegionList/updateItem", obj).then(() => {
                this.$store.dispatch("RegionList/fetchDataListItems");
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            delete obj.ID;
            obj.popularity = 0;
            this.$store
              .dispatch("RegionList/addItem", obj)
              .then(() => {
                this.$store.dispatch("RegionList/fetchDataListItems");
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          }

          this.$emit("closeSidebar");
          this.initValues();
        }
      });
    },
  },  
  created() {
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("countriesList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    this.$store.dispatch("countriesList/fetchDataListItems");
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
.vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
.sclass{
  background-color: white !important;
}
</style>
